/* eslint-disable import/prefer-default-export */
import Icon1 from '../assets/goodsCardsIcons/1.png';
import Icon2 from '../assets/goodsCardsIcons/2.png';
import Icon3 from '../assets/goodsCardsIcons/3.png';
import Icon4 from '../assets/goodsCardsIcons/4.png';
import Icon5 from '../assets/goodsCardsIcons/5.png';
import Icon6 from '../assets/goodsCardsIcons/6.png';
import Icon7 from '../assets/goodsCardsIcons/7.png';
import Icon8 from '../assets/goodsCardsIcons/8.png';
import Icon9 from '../assets/goodsCardsIcons/9.png';
import Icon10 from '../assets/goodsCardsIcons/10.png';

export const GOODS_CATEGORIES_CARDS_DATA = {
  ru: [
    {
      icon: Icon1,
      title: 'Плоская кровля ',
      label:
        'Система не эксплуатируемого или эксплуатируемой крыши по несущей основе с кровельным ковром из мембраны и комбинированным утеплением. ',
    },
    {
      icon: Icon2,
      title: 'Скатная кровля ',
      label: 'Система крыши для устройства кровли в коттеджном и малом строительстве.',
    },
    {
      icon: Icon3,
      title: 'Фасад ',
      label: 'Обустройство теплоизоляции фасада на основе минерального утеплителя или экструдированного пенополистирола. ',
    },
    {
      icon: Icon4,
      title: 'Фундамент ',
      label: 'Комплекс материалов для качественной гидроизолиции фундамента, паркингов. ',
    },
    {
      icon: Icon5,
      title: 'Тоннель ',
      label: 'Гидроизоляционная система тоннелей и комплекс материалов для ее выполнения. ',
    },
    {
      icon: Icon6,
      title: 'Пол',
      label: 'Система материалов по обустройству гидро и теплоизоляции пола. ',
    },
    {
      icon: Icon7,
      title: 'Защитные покрытия ',
      label: 'Комплекс антикоррозионных и гидроизоляционных материалов. ',
    },
    {
      icon: Icon8,
      title: 'Дорога',
      label:
        'Конструкция из комплекса материалов по утеплению и гидроизоляции дорог, мостов и других конструктивов для дорожного полотна. ',
    },
    {
      icon: Icon9,
      title: 'Техническая изоляция',
      label: 'Группа материалов для технической изоляции в зданиях. ',
    },
    {
      icon: Icon10,
      title: 'Огнезащита ',
      label: 'Группа материалов на основе базальтового волокна для защиты от высоких температур. ',
    },
  ],
  ua: [
    {
      icon: Icon1,
      title: 'Пласка покрівля',
      label:
        'Системи не експлуатованого або експлуатованого даху по несучій основі з покрівельним килимом з  мембрани і комбінованим утепленням.',
    },
    {
      icon: Icon2,
      title: 'Скатний дах',
      label: 'Система даху для облаштування покрівлі в котеджному та малому будівництві.',
    },
    {
      icon: Icon3,
      title: 'Фасад',
      label: 'Облаштування теплоізоляції фасаду на основі мінерального утеплювача або екструдованого пінополістиролу.',
    },
    {
      icon: Icon4,
      title: 'Фундамент',
      label: 'Комплекс матеріалів для якісної гідроізоліції фундаменту, паркінгів.',
    },
    {
      icon: Icon5,
      title: 'Тунель',
      label: 'Гідроізоляційна система тунелів та комплекс матеріалів для її виконання.',
    },
    {
      icon: Icon6,
      title: 'Підлога',
      label: 'Система матеріалів по облаштуванню гідро та теплоізоляції підлоги.',
    },
    {
      icon: Icon7,
      title: 'Захисні покриття',
      label: 'Комплекс антикорозійних та гідроізоляційних матеріалів.',
    },
    {
      icon: Icon8,
      title: 'Дорога',
      label:
        'Конструкція з комплексу матеріалів по утепленню та гідроізоляції доріг, мостів та інших конструктивів для дорожнього полотна.',
    },
    {
      icon: Icon9,
      title: 'Технічна ізоляція',
      label: 'Група матеріалів для технічної ізоляції в будівлях.',
    },
    {
      icon: Icon10,
      title: 'Вогнезахист',
      label: 'Група матеріалів на основі базальтового волокна для захисту від високих температур.',
    },
  ],
};
